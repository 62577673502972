//@ts-check
import {
  Button,
  ButtonGroup,
  Content,
  Flex,
  Footer,
  Heading,
  InlineAlert,
  ProgressCircle,
  Text,
  useDialogContainer,
} from '@adobe/react-spectrum';
import React, { useContext } from 'react';

import { ProductProfilesContext } from './ProductProfilesProvider';
import ServicesList from './ServicesList';

const SpProductProfilesDialogContent = () => {
  const { dismiss } = useDialogContainer();
  const {
    selectedProductProfiles,
    editedProductProfiles,
    editProfilesMutation,
    saveProductProfiles,
    refetchingSelectedProfiles,
  } = useContext(ProductProfilesContext);
  const errorMessage = editProfilesMutation.error?.jsonResponse?.errorMessage;
  const savingProfiles = editProfilesMutation.isLoading || refetchingSelectedProfiles;
  const isContinueDisabled = !selectedProductProfiles.length || savingProfiles || !editedProductProfiles?.length;

  return (
    <>
      <Content maxHeight="100%">
        <Flex maxHeight="100%" gap="size-400" direction="column">
          <Text>
            Select an API and choose the product profiles to assign. Your integration&apos;s service account will gain
            access to granular features through the product profiles selected here.
          </Text>
          <ServicesList />
        </Flex>
      </Content>
      <Footer>
        <Flex direction="column" gap="size-200" width="100%">
          {errorMessage && (
            <InlineAlert variant="negative" marginTop="-20px">
              <Heading>Something went wrong while updating product profiles</Heading>
              <Content>{errorMessage}</Content>
            </InlineAlert>
          )}
          <ButtonGroup alignSelf="end">
            <Button variant="secondary" onPress={dismiss} isDisabled={savingProfiles}>
              Cancel
            </Button>
            <Button variant="accent" minWidth="265px" isDisabled={isContinueDisabled} onPress={saveProductProfiles}>
              {savingProfiles ? (
                <ProgressCircle isIndeterminate size="S" aria-label="Updating product profiles" />
              ) : (
                <Text>Continue with selected product profiles</Text>
              )}
            </Button>
          </ButtonGroup>
        </Flex>
      </Footer>
    </>
  );
};

export default SpProductProfilesDialogContent;
