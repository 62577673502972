//@ts-check
import { RequestError } from './RequestError';

/** A class to handle requests to the server and parse the response. */
export class RequestClient {
  /**
   * @param {string} url
   * @param {Record<string, string>} [commonHeaders]
   */
  constructor(url, commonHeaders) {
    this.URL = url;
    this.commonHeaders = commonHeaders || {};
  }

  static NO_RES_BODY_METHODS = Object.freeze(['TRACE', 'HEAD', 'OPTIONS']);

  /**
   * @description Converts a Headers instance to a plain object.
   * @param {HeadersInit | undefined} headers - The Headers instance to convert.
   */
  static headersToObject(headers) {
    const headersObj = {};
    if (headers instanceof Headers) {
      headers.forEach((value, key) => {
        headersObj[key] = value;
      });
    } else if (headers) {
      Object.assign(headersObj, headers);
    }
    return headersObj;
  }

  /**
   * @description Returns the default headers for the request.
   * @param {RequestInit } requestOptions
   */
  static getDefaultHeaders(requestOptions) {
    const headers = new Headers({ accept: 'application/json' });
    if (requestOptions.body && !(requestOptions.body instanceof FormData)) {
      headers.set('content-type', 'application/json');
    }
    return RequestClient.headersToObject(headers);
  }

  /**
   * @description Merges the common headers and default headers with the request headers.
   * @param {RequestInit} requestOptions - Headers specific to the current request.
   */
  mergeHeaders(requestOptions) {
    const requestHeadersObj = RequestClient.headersToObject(requestOptions?.headers) || {};
    const defaultHeaders = RequestClient.getDefaultHeaders(requestOptions);
    requestOptions.headers = new Headers({ ...defaultHeaders, ...this.commonHeaders, ...requestHeadersObj });
  }

  /**
   * @typedef {object} AdditionalRequestOptions
   * @property {boolean} [parseBodyOnSuccess] - Whether to parse the response body as JSON when the request is successful
   * @property {boolean} [parseBodyOnError] - Whether to parse the response body as JSON when the request fails
   */

  /**
   * @description Fetches data from the server and parses the response
   * @template ServerResponse
   * @template ServerErrorResponse
   * @param {Object} options
   * @param {string} options.endpoint - The endpoint to fetch the data from
   * @param {RequestInit & AdditionalRequestOptions} [options.requestOptions] - The options to pass to the fetch function
   * @param {string} [options.defaultErrorMessage] - The default error message to show if the server response does not return an error message
   * @returns {Promise<ServerResponse>}
   * @throws {RequestError<ServerErrorResponse>}
   */
  async fetch({ endpoint, requestOptions: options = {}, defaultErrorMessage }) {
    const { parseBodyOnSuccess, parseBodyOnError, ...requestOptions } = options;
    this.mergeHeaders(requestOptions);

    let fetchResponse;
    try {
      fetchResponse = await fetch(`${this.URL}${endpoint}`, requestOptions);
    } catch (error) {
      console.error('Error fetching data:', endpoint, error);
      throw new RequestError({ originalError: error, message: defaultErrorMessage });
    }

    let textResponse;
    let jsonResponse;
    const requestMethod = requestOptions.method || 'GET';
    if (!RequestClient.NO_RES_BODY_METHODS.includes(requestMethod)) {
      try {
        textResponse = await fetchResponse.text();
      } catch (error) {
        console.error('Error getting text response:', endpoint, error, fetchResponse);
        throw new RequestError({ fetchResponse, originalError: error, message: defaultErrorMessage });
      }

      const parseBody = fetchResponse.ok ? parseBodyOnSuccess : parseBodyOnError;
      if (parseBody ?? true) {
        try {
          if (textResponse) {
            jsonResponse = JSON.parse(textResponse);
          }
        } catch (error) {
          console.error('Error parsing JSON:', endpoint, error, textResponse);
          throw new RequestError({ fetchResponse, textResponse, originalError: error, message: defaultErrorMessage });
        }
      }
    }

    if (!fetchResponse?.ok) {
      console.error('Error response from server:', endpoint, fetchResponse, jsonResponse);
      const message = jsonResponse?.message || defaultErrorMessage;
      /** @type {RequestError<ServerErrorResponse>} */
      const requestError = new RequestError({ fetchResponse, textResponse, jsonResponse, message });
      throw requestError;
    } else {
      return jsonResponse || textResponse || null;
    }
  }

  /**
   * @param {string} header
   * @param {string} value
   */
  setCommonHeader(header, value) {
    this.commonHeaders[header] = value;
  }
}

export const myxchngApiClient = new RequestClient(`${process.env.APP_REGISTRY_URL}/myxchng/v2`, {
  'x-api-key': `${process.env.API_KEY}`,
});

export const utilApiClient = new RequestClient(`${process.env.APP_REGISTRY_URL}/util-api/v2`, {
  'x-api-key': `${process.env.API_KEY}`,
});

export const entitlementApiClient = new RequestClient(`${process.env.APP_REGISTRY_URL}/entitlement-api/v2`, {
  'x-api-key': `${process.env.API_KEY}`,
});

export const consoleApiClient = new RequestClient(`${process.env.DEVELOPER_CONSOLE_API}/console/organizations`, {
  'x-api-key': `${process.env.API_KEY}`,
});

export const jilApiClient = new RequestClient(`${process.env.JIL_API}/jil-api/v2`, {
  'x-api-key': `${process.env.API_KEY}`,
});
