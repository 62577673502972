import {
  ActionGroup,
  DialogContainer,
  Item,
  Link,
  ProgressCircle,
  Provider as ProviderV3,
  Text,
  defaultTheme,
} from '@adobe/react-spectrum';
import { Cell, Column, Row, TableBody, TableHeader, TableView } from '@react-spectrum/table';
import React, { useContext, useState } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { useMediaQuery } from '@exchange-frontends/custom-hooks';

import { useStore } from '../../../../store';
import { buildAppListingUrl, formatDate } from '../../../../utils/ECAppsExtensionsHelper';
import { UserDataContext } from '../../../App';
import ECCardButton from '../buttons/ECCardButton';
import ManageContactPublisherDialog from './ManageContactPublisherDialog';
import AppStatusLight from './common/AppStatusLight';
import ExtensionDetails from './common/ExtensionDetails';
import ProductCell from './common/ProductCell';
import { FormattedCell } from './common/StyledComponents';

export const ColumnTitle = styled.span`
  text-align: left;
  font: normal normal normal 11px/14px adobe-clean;
  letter-spacing: 0.66px;
  color: #6e6e6e;
  opacity: 1;
`;

export const AppName = styled(RouterLink)`
  text-align: left;
  font: normal normal bold 16px/21px adobe-clean;
  margin-bottom: 10px;
  display: block;
`;

export const columns = [
  {
    uid: 'application',
    name: 'Application',
    sortable: false,
  },
  {
    uid: 'status',
    name: 'Status',
    sortable: false,
  },
  {
    uid: 'dateAcquired',
    name: 'Date acquired',
    sortable: false,
  },
  {
    uid: 'distribution',
    name: 'Distribution',
    sortable: false,
  },
  {
    uid: 'products',
    name: 'Products',
    sortable: false,
  },
  {
    uid: 'actions',
    name: 'Actions',
    sortable: false,
  },
];
const AppsTable = (props) => {
  const { loading, apps, appBuilderExtensionPoints, selectedOrg } = props;
  const isMobileSized = useMediaQuery('(max-width: 800px)');
  const [isOpen, setIsOpen] = useState(false);
  const [currentAppId, setCurrentAppId] = useState(null);
  const fetchCurrentApp = useStore((state) => state.fetchCurrentApp);
  const { orgId } = useParams();
  const { accessToken } = useContext(UserDataContext) ?? {};
  const onItemAction = (key, appId) => {
    if (key === 'get_support') {
      setIsOpen(true);
      setCurrentAppId(appId);
    }
  };

  const getAppManageUrl = (app) => {
    return `/manage/apps/ec/${selectedOrg.code}/ABD/view/${app?.id}`;
  };

  const redirectToHelpUrl = async (app) => {
    let helpUrl = app?.assets?.find((item) => item?.type === 'document');
    if (!helpUrl) {
      const response = await fetchCurrentApp(accessToken, orgId, app.id);
      const updatedApp = response.currentApp ?? {};
      helpUrl = updatedApp?.assets?.find((item) => item?.type === 'document');
    }
    window.open(helpUrl?.url, '_blank');
  };

  const getColumnContent = (item, columnKey) => {
    switch (columnKey) {
      case 'application':
        return (
          <>
            <Link isQuiet>
              <AppName to={getAppManageUrl(item)}>
                {item?.distribution === 'private' ? item?.title : item?.name}
              </AppName>
            </Link>
            <ExtensionDetails app={item} appBuilderExtensionPoints={appBuilderExtensionPoints} />
          </>
        );
      case 'status':
        return <AppStatusLight status={item?.status} data-testid="EC_APPS_TABLE_ROW__STATUS" />;
      case 'dateAcquired':
        return (
          <span data-testid="EC_APPS_TABLE_ROW__DATE_ACQUIRED">
            {item?.distribution === 'private'
              ? item?.lastModifiedDate
              : item?.createdOn
              ? formatDate(item?.createdOn)
              : 'N/A'}
          </span>
        );
      case 'distribution':
        return (
          <span data-testid="EC_APPS_TABLE_ROW__DISTRIBUTION">
            {item?.distribution === 'private' ? 'Private' : 'Public'}
          </span>
        );
      case 'products':
        return <ProductCell item={item} />;
      case 'actions':
        return (
          <>
            <FormattedCell $isMobileSized={isMobileSized} style={{ justifyContent: 'flex-end' }}>
              <ECCardButton
                data-testid="EC_APPS_TABLE_ROW__CTA_BUTTON"
                app={item}
                loading={loading}
                appBuilderExtensionPoints={appBuilderExtensionPoints}
                isReviewButton={item?.status?.toLowerCase() === 'pending'}
                isAdmin={selectedOrg?.role === 'ADMIN'}
              />
              <ActionGroup
                data-testid="EC_APPS_TABLE_ROW__MORE_ACTIONS_BUTTON"
                overflowMode="collapse"
                buttonLabelBehavior="collapse"
                maxWidth="size-350"
                marginTop={isMobileSized ? 'size-100' : 'size-0'}
                marginRight={isMobileSized ? 'size-0' : 'size-100'}
                alignSelf={isMobileSized ? 'center' : 'flex-start'}
                onAction={(key) => onItemAction(key, item.id)}
                disabledKeys={
                  item?.distribution !== 'public' ? ['view_app_documentation', 'view_app_listing', 'get_support'] : []
                }
              >
                <Item key="view_app_details" textValue="Manage your app">
                  <Text>
                    <Link variant="secondary" isQuiet>
                      <RouterLink to={getAppManageUrl(item)}>View app details</RouterLink>
                    </Link>
                  </Text>
                </Item>
                {item?.distribution === 'public' && (
                  <Item key="view_app_documentation" textValue="View app documentation">
                    <Text>
                      <Link variant="secondary" isQuiet onPress={() => redirectToHelpUrl(item)}>
                        View app documentation
                      </Link>
                    </Text>
                  </Item>
                )}
                {item?.distribution === 'public' && item?.isRetractedApp === false && (
                  <Item key="view_app_listing" textValue="View listing">
                    <Text>
                      <Link variant="secondary" isQuiet href={buildAppListingUrl(item)} target="_blank">
                        View listing
                      </Link>
                    </Text>
                  </Item>
                )}
                {item?.distribution === 'public' && (
                  <Item key="get_support" textValue="Get support">
                    <Text>Get support</Text>
                  </Item>
                )}
              </ActionGroup>
            </FormattedCell>
          </>
        );
      default:
        return item[columnKey];
    }
  };
  return (
    <ProviderV3 theme={defaultTheme} colorScheme={`light`}>
      {!loading ? (
        <TableView
          overflowMode="wrap"
          aria-label="Search Apps List View"
          density="spacious"
          minHeight="size-3000"
          width="100%"
          data-testid="EC_APPS_TABLE"
        >
          <TableHeader columns={columns}>
            {(column) => (
              <Column
                allowsSorting={column.sortable}
                key={column.uid}
                align={column.uid === 'actions' ? 'end' : 'start'}
                maxWidth={
                  column.uid === 'distribution' || column.uid === 'dateAcquired' || column.uid === 'products'
                    ? '15%'
                    : '20%'
                }
              >
                <ColumnTitle>{column.name}</ColumnTitle>
              </Column>
            )}
          </TableHeader>
          <TableBody items={apps}>
            {(item) => <Row key={item.id}>{(columnKey) => <Cell>{getColumnContent(item, columnKey)}</Cell>}</Row>}
          </TableBody>
        </TableView>
      ) : (
        <ProgressCircle aria-label="Loading Experience Cloud apps..." marginTop="size-1000" size="L" isIndeterminate />
      )}
      <DialogContainer onDismiss={() => setIsOpen(false)}>
        {isOpen && <ManageContactPublisherDialog appId={currentAppId} />}
      </DialogContainer>
    </ProviderV3>
  );
};

export default AppsTable;
