//@ts-check
import { entitlementApiClient } from '../../utils/RequestClient';
import RequestSearchParams from '../../utils/RequestSearchParams';

/**
 * @param {object} params
 * @param {string} params.orgId
 * @param {string} params.token
 * @param {number | string} [params.pageIndex]
 * @param {number | string} [params.pageSize]
 * @param {'DESC' | 'ASC'} [params.order]
 * @param {string} [params.sort]
 * @param {'S2S' | 'ABD'} [params.appType]
 * @param {string} [params.appId]
 * @param {AbortSignal} [params.signal]
 * @returns {Promise<import('@action-types/entitlement').EnterpriseEntitlementsResponse>}
 * @throws {import('@action-types/RequestError').RequestError<import('@action-types/entitlement').EntitlementApiErrorResponse>}
 */
const getEnterpriseEntitlements = ({
  token,
  signal,
  orgId,
  appId,
  sort = 'CREATED',
  order = 'DESC',
  pageIndex = 0,
  pageSize = 20,
  appType = 'ABD',
}) => {
  const searchParams = new RequestSearchParams({
    pageIndex,
    pageSize,
    appType,
    order,
    sort,
    appId,
  });
  return entitlementApiClient.fetch({
    endpoint: `/enterprise/entitlements${searchParams}`,
    requestOptions: {
      signal,
      headers: {
        'x-org-id': orgId,
        authorization: token,
      },
    },
  });
};

export default getEnterpriseEntitlements;
