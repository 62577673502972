import {
  Button,
  ButtonGroup,
  Content,
  Dialog,
  DialogContainer,
  Divider,
  Flex,
  Heading,
  Image,
  Link,
  ProgressCircle,
  Text,
  TextArea,
  View,
} from '@adobe/react-spectrum';
import LinkOutLight from '@spectrum-icons/workflow/LinkOutLight';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { useStore } from '../../../store';
import { CONSENT_ACTIONS } from '../../../utils/Constants';
import { getIconUrl } from '../../../utils/ECAppsExtensionsHelper';
import { UserDataContext } from '../../App';
import { ImageContainer } from './ManageCard';
import ExtensionDetails from './ec/common/ExtensionDetails';

export const ErrorContainer = styled.div`
  padding: var(--spectrum-global-dimension-size-150);
  background-color: var(--spectrum-alias-icon-color-error);
  color: white;
  margin-top: 18px;
  border-radius: 5px;
`;

const ReviewDialog = ({ app, close, appBuilderExtensionPoints, redirectHandler, ...rest }) => {
  const reviewXRApp = useStore((state) => state.reviewXRApp);
  const updateConsent = useStore((state) => state.updateConsent);
  const [action, setAction] = useState(null);
  const [reviewerNotes, setReviewerNotes] = useState('');
  const [error, setError] = useState(null);
  const { accessToken } = useContext(UserDataContext);
  const { orgId, appId } = useParams();
  const [valid, setValid] = useState(null);
  const [viewMore, setViewMore] = useState(false);
  const [viewMoreNotes, setViewMoreNotes] = useState(false);
  const [isAgreementVisible, setIsAgreementVisible] = useState(false);
  const fetchCurrentApp = useStore((state) => state.fetchCurrentApp);
  const [eulaData, setEulaData] = useState('');

  // Variables and Constants
  const notes = (app.jaeger ? app.developersNotes : app.releaseNotes) ?? 'No notes from the developer';

  // Review Use Effect
  useEffect(() => {
    if (action) {
      setError(null);
      if (app.jaeger && app.distribution === 'private') {
        reviewXRApp(accessToken, orgId, app.id, action, reviewerNotes, redirectHandler, close).then((response) => {
          if (!response.ok) {
            setAction(null);
            setIsAgreementVisible(false);
            setError(response.error);
          } else {
            setAction(null);
            setIsAgreementVisible(false);
            redirectHandler && redirectHandler();
          }
        });
      } else if (app.distribution === 'public' && app.appType === 'ABD') {
        updateConsent(accessToken, orgId, app.id, app.appVersionId, action, reviewerNotes, close).then((response) => {
          if (!response.ok) {
            setAction(null);
            setError(response.error);
            setIsAgreementVisible(false);
          } else {
            const isApprove = action === CONSENT_ACTIONS.ALLOW;
            setAction(null);
            setIsAgreementVisible(false);
            if (!isApprove && redirectHandler) {
              redirectHandler();
            }
          }
        });
      }
    }
  }, [action, accessToken, app]);

  const validateNotes = async (actionVal) => {
    const isValid = reviewerNotes.length >= 1;
    setValid(isValid ? 'valid' : 'invalid');

    if (app?.distribution === 'private') {
      setAction(isValid ? actionVal : null);
    } else if (actionVal === CONSENT_ACTIONS.DENY && isValid) {
      setAction(actionVal);
    } else {
      let eula = app?.eula || '';

      if (!eula && appId === undefined) {
        const response = await fetchCurrentApp(accessToken, orgId, app.id);
        if (response.ok) {
          eula = response.currentApp?.eula;
        }
      }
      setEulaData(eula);
      setIsAgreementVisible(true);
    }
  };
  return (
    <>
      {!isAgreementVisible && (
        <Dialog {...rest} maxWidth="480px" width="90vw" data-testid="review-dialog">
          <Heading>Review this application</Heading>
          <Divider width="100%" size="S" />
          <Content>
            <Flex wrap="wrap" width="100%" gap="size-100">
              <Flex width="100%">
                {notes.length > 150 ? (
                  <div>
                    {viewMoreNotes ? notes : `${notes?.substring(0, 150)}…`}
                    <Link marginStart="size-50" isQuiet onPress={() => setViewMoreNotes(!viewMoreNotes)}>
                      {viewMoreNotes ? 'View less' : 'View more'}
                    </Link>
                  </div>
                ) : (
                  <div>{notes}</div>
                )}
              </Flex>
              <Flex alignItems="center">
                <ImageContainer style={{ display: 'flex', alignItems: 'center' }}>
                  <Image alt="App icon" width="40px" height="40px" src={getIconUrl(app)} />
                </ImageContainer>
                <View marginEnd="auto">
                  <Heading margin="0">{app.distribution === 'private' ? app?.title : app?.name}</Heading>
                  <ExtensionDetails app={app} appBuilderExtensionPoints={appBuilderExtensionPoints} />
                </View>
              </Flex>
              <Flex width="100%">
                {app?.description?.length > 150 ? (
                  <div>
                    {viewMore ? app?.description : `${app?.description?.substring(0, 150)}…`}
                    <Link marginStart="size-50" isQuiet onPress={() => setViewMore(!viewMore)}>
                      {viewMore ? 'View less' : 'View more'}
                    </Link>
                  </div>
                ) : (
                  <div>{app?.description}</div>
                )}
              </Flex>
              <Flex width="100%" wrap="wrap">
                <TextArea
                  label="Reviewer's notes"
                  placeholder="Enter your reviewer's comments"
                  width="100%"
                  isRequired
                  necessityIndicator="icon"
                  validationState={valid}
                  errorMessage={valid === 'valid' ? '' : "Please enter reviewer's notes"}
                  onChange={(value) => setReviewerNotes(value)}
                />
                {error && <ErrorContainer>{error}</ErrorContainer>}
              </Flex>
            </Flex>
          </Content>
          <ButtonGroup>
            <Button onPress={close} variant="primary" isDisabled={!!action} data-launchid="Cancel reviewing app">
              Cancel
            </Button>
            <Button
              variant="negative"
              isDisabled={!!action || !reviewerNotes.length >= 1}
              onPress={() => (app.distribution === 'private' ? validateNotes('REJECT') : validateNotes('DENY'))}
              data-launchid="Reject the app"
            >
              Reject
              {action === 'REJECT' && (
                <ProgressCircle size="S" isIndeterminate aria-label="Rejecting the app" marginStart="size-150" />
              )}
            </Button>
            <Button
              variant="cta"
              isDisabled={!!action || !reviewerNotes.length >= 1}
              onPress={() => (app.distribution === 'private' ? validateNotes('APPROVE') : validateNotes('ALLOW'))}
              data-launchid="Approve the app"
            >
              Approve
              {action === 'APPROVE' && (
                <ProgressCircle size="S" isIndeterminate aria-label="Approving the app" marginStart="size-150" />
              )}
            </Button>
          </ButtonGroup>
        </Dialog>
      )}
      {app?.distribution === 'public' && isAgreementVisible && (
        <DialogContainer
          onDismiss={() => {
            setIsAgreementVisible(false);
          }}
        >
          <Dialog maxWidth="size-6000" width="90vw" minHeight="200px">
            {action === CONSENT_ACTIONS.ALLOW && (
              <Content>
                <Flex alignItems="center" direction="column" justifyContent="center" height="100%">
                  <ProgressCircle size="L" isIndeterminate aria-label="Approving the app" marginStart="size-150" />
                  <em>Approving application…</em>
                </Flex>
              </Content>
            )}
            {Object.is(action, null) && (
              <>
                <Heading>End-User License Agreement</Heading>
                <Divider />
                <Content>
                  <Text>
                    Before approving this application to be used in your enterprise org, please review the terms and
                    conditions of the end-user license agreement.
                  </Text>
                  <View height="size-150" />
                  <Flex alignItems="center" gap="size-50">
                    <Link variant="primary">
                      <a href={eulaData} target="_blank" rel="noreferrer">
                        Read end-user license agreement
                      </a>
                    </Link>
                    <LinkOutLight size="XS" height="size-175" color="informative" />
                  </Flex>
                </Content>
                <ButtonGroup>
                  <Button
                    variant="secondary"
                    onPress={() => {
                      setIsAgreementVisible(false);
                      setReviewerNotes('');
                      setValid('');
                    }}
                  >
                    Cancel
                  </Button>
                  <Button variant="cta" onPress={() => setAction(CONSENT_ACTIONS.ALLOW)}>
                    Agree and Continue
                  </Button>
                </ButtonGroup>
              </>
            )}
          </Dialog>
        </DialogContainer>
      )}
    </>
  );
};

export default ReviewDialog;
