import useServicesBySdkCodes from './useServicesBySdkCodes';

/** @param {import('@action-types/ecApp').AppDetails} appDetails */
const useEcAppSdkCodes = (appDetails) => {
  const sdkCodes = appDetails?.sdks?.map((sdk) => sdk?.sdkCode);
  const servicesQuery = useServicesBySdkCodes(sdkCodes, {
    enabled: sdkCodes?.length > 0,
  });

  return servicesQuery;
};

export default useEcAppSdkCodes;
