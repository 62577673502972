//@ts-check
import {
  Button,
  ButtonGroup,
  Content,
  Dialog,
  Divider,
  Flex,
  Heading,
  Image,
  Link,
  ProgressCircle,
  Text,
  View,
} from '@adobe/react-spectrum';
import { error as errorToast } from '@react/react-spectrum/Toast/js/ToastContainer';
import { useMutation } from '@tanstack/react-query';
import React, { useContext, useMemo } from 'react';

import { useStaticData } from '@exchange-frontends/custom-hooks';
import { getProductNames } from '@exchange-frontends/utils';

import illustrationPath from '../../../../static/illustrations/Illu_ConsentFlow_320x180.png';

import generateStateToken from '../../../actions/myxchngApi/actions/generateStateToken';
import { LINKS } from '../../../constants';
import { ContainerContext } from '../../../utils';

/** @type {import('.').DialogTitle} */
const titleMap = {
  beginInstallation: 'Additional installation steps required',
  applicationAcquired: 'Application acquired successfully',
};

/**
 * @typedef EcAcquiredAdminDialogProps
 * @property {string[]} [requiredProducts]
 * @property {string[]} [optionalProducts]
 * @property {import('.').DialogVariant} [dialogVariant]
 * @property {string} appId
 * @property {string} appType
 * @property {string} orgId
 * @property {() => void} close
 */

/** @param {EcAcquiredAdminDialogProps} props */
const EcAcquiredAdminDialog = ({
  close,
  requiredProducts,
  optionalProducts,
  dialogVariant = 'beginInstallation',
  appId,
  appType,
  orgId,
}) => {
  const { ims } = useContext(ContainerContext);
  const token = ims?.getAccessToken()?.token;
  /** @type { import('@action-types/ecApp').GenerateStateTokenMutationState} */
  const generateStateTokenMutation = useMutation({
    mutationKey: ['generate-state-token'],
    mutationFn: () => generateStateToken({ token, appId, appType, orgId }),
  });
  const productLocs = useStaticData('hostApps');

  const mappedRequiredProducts = useMemo(
    () => getProductNames(requiredProducts, productLocs),
    [requiredProducts, productLocs]
  );

  const mappedOptionalProducts = useMemo(
    () => getProductNames(optionalProducts, productLocs),
    [optionalProducts, productLocs]
  );

  const onContinue = async () => {
    try {
      const stateToken = await generateStateTokenMutation.mutateAsync();
      // @TODO: redirect to IMS browser based workflow
      close();
    } catch (error) {
      close();
      errorToast(error?.message, { timeout: 0 });
    }
  };

  return (
    <Dialog>
      <Heading>{titleMap[dialogVariant]}</Heading>
      <Divider />
      <Content>
        <Flex direction="column" gap="size-200">
          <Text>
            This application has some extra steps to configure before app installation. This process must be completed
            by the system administrator of your organization&apos;s Adobe account.
          </Text>
          <View backgroundColor="static-white" padding="size-200">
            <Flex gap="size-200">
              <Image
                isHidden={{ base: true, S: false }}
                src={illustrationPath.startsWith('/') ? illustrationPath : `/${illustrationPath}`}
                alt="Consent flow"
              />
              <Text>
                You are about to be taken to a browser based workflow to approve the connection between this application
                and your Adobe organization account.
              </Text>
            </Flex>
          </View>
          {mappedRequiredProducts?.length > 0 && (
            <>
              <Text>
                Please make sure your organization has the following product(s) that the publisher selected as required
                for this application.
              </Text>
              <View backgroundColor="static-white" padding="size-200">
                <Heading level={4} margin={0}>
                  {mappedRequiredProducts.join(', ')}
                </Heading>
              </View>
            </>
          )}
          {mappedOptionalProducts?.length > 0 && (
            <>
              <Text>
                The below Adobe products are {mappedRequiredProducts?.length ? 'also ' : ''}supported by this
                application, but it doesn&apos;t require them to work.
              </Text>
              <View backgroundColor="static-white" padding="size-200">
                <Heading level={4} margin={0}>
                  {mappedOptionalProducts.join(', ')}
                </Heading>
              </View>
            </>
          )}
          <Text>
            After access has been granted by the system administrator, you will be able to proceed with the installation
            as normal.
          </Text>
          <Text>
            <Link>
              <a target="_blank" rel="noopener noreferrer" href={LINKS.SP.VIEW_DOCS}>
                Read more
              </a>
            </Link>{' '}
            about accessing and managing service integration applications.
          </Text>
        </Flex>
      </Content>
      <ButtonGroup isDisabled={generateStateTokenMutation.isLoading}>
        <Button variant="secondary" onPress={close}>
          Cancel
        </Button>
        <Button minWidth="90px" variant="cta" onPress={onContinue}>
          {generateStateTokenMutation.isLoading ? (
            <ProgressCircle size="S" aria-label="Redirecting to consent page" isIndeterminate />
          ) : (
            <Text>Continue</Text>
          )}
        </Button>
      </ButtonGroup>
    </Dialog>
  );
};

export default EcAcquiredAdminDialog;
