//@ts-check
import { Button, ButtonGroup, Content, Dialog, Divider, Heading, useDialogContainer } from '@adobe/react-spectrum';
import React from 'react';

import ProductProfilesProvider from './ProductProfilesProvider';
import SpProductProfilesDialogContent from './SpProductProfilesDialogContent';
import useLoadingAndErrorState from './useLoadingAndErrorState';

/** @param {import('../../tabs/apps-list/IntegrationsList/types/integrationsTable').CellContentProps} props */
const SpProductProfilesDialog = ({ data }) => {
  const { dismiss } = useDialogContainer();

  let dialogContent;
  const loadingAndErrorState = useLoadingAndErrorState(data);

  if (loadingAndErrorState) {
    dialogContent = <Content>{loadingAndErrorState}</Content>;
  } else {
    dialogContent = (
      <ProductProfilesProvider data={data}>
        <SpProductProfilesDialogContent />
      </ProductProfilesProvider>
    );
  }

  return (
    <Dialog width="90vw" minHeight="90vh" maxWidth="900px">
      <Heading>Select product profiles</Heading>
      <Divider />
      {dialogContent}
      {loadingAndErrorState && (
        <ButtonGroup>
          <Button variant="secondary" onPress={dismiss}>
            Cancel
          </Button>
        </ButtonGroup>
      )}
    </Dialog>
  );
};

export default SpProductProfilesDialog;
