import useEcAppSdkCodes from '../../../../../../actions/consoleApi/selectors/useEcAppSdkCodes';
import useConsent from '../../../../../../actions/entitlementApi/selectors/ecAppDetails/useConsent';
import useUserDetails from '../../../../../../actions/jilApi/selectors/useUserDetails';
import useEcAppDetails from '../../../../../../actions/myxchngApi/selectors/useEcAppDetails';

const useIntegrationDetailsData = () => {
  const consentQuery = useConsent();
  const appDetailsQuery = useEcAppDetails();
  const technicalAccountId = consentQuery.data?.consent?.technicalAccountId;
  const servicesQuery = useEcAppSdkCodes(appDetailsQuery.data);
  const services = servicesQuery.data?.services;
  const userQuery = useUserDetails(technicalAccountId, { enabled: services?.length > 0 });

  return {
    consentQuery,
    appDetailsQuery,
    servicesQuery,
    userQuery,
  };
};

export default useIntegrationDetailsData;
