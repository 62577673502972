//@ts-check
import { consoleApiClient } from '../../utils/RequestClient';
import RequestSearchParams from '../../utils/RequestSearchParams';

/**
 * @param {object} params
 * @param {string} params.orgId
 * @param {string} params.token
 * @param {string[]} [params.sdkCodes]
 * @param {AbortSignal} [params.signal]
 * @returns {Promise<import('@action-types/service').ServicesResponse>}
 * @throws {import('@action-types/RequestError').RequestError<import('@action-types/service').ConsoleApiErrorResponse>}
 */
const getServicesBySdkCodes = ({ signal, token, orgId, sdkCodes }) => {
  const searchParams = new RequestSearchParams({ sdkCodes: sdkCodes?.join(',') });
  return consoleApiClient.fetch({
    endpoint: `/${orgId}/services/v2${searchParams}`,
    requestOptions: {
      signal,
      headers: {
        authorization: token,
      },
    },
  });
};

export default getServicesBySdkCodes;
