//@ts-check
import { AlertDialog, DialogContainer, Flex, Text } from '@adobe/react-spectrum';
import { success } from '@react/react-spectrum/Toast/js/ToastContainer';
import React, { useEffect, useState } from 'react';

import useEcAppSdkCodes from '../../../../../../actions/consoleApi/selectors/useEcAppSdkCodes';
import useConsent from '../../../../../../actions/entitlementApi/selectors/ecAppDetails/useConsent';
import useEcAppDetails from '../../../../../../actions/myxchngApi/selectors/useEcAppDetails';
import { WORKFLOWS } from './IntegrationDetails';
import useWorkflow from './useWorkflow';

const AccessGrantedDialog = () => {
  const [toastShown, setToastShown] = useState(false);

  const consentData = useConsent();
  const appDetailsQuery = useEcAppDetails();
  const servicesQuery = useEcAppSdkCodes(appDetailsQuery.data);
  const { workflow, setWorkflow } = useWorkflow();

  const hasProductProfiles = servicesQuery.data?.services?.length > 0;

  const status = consentData.data?.consent?.status;
  const shouldShowConfirmation = workflow === WORKFLOWS.CONSENTED && status === 'ALLOWED';
  const isDialogOpen = shouldShowConfirmation && hasProductProfiles;
  const shouldShowSuccessToast = shouldShowConfirmation && !hasProductProfiles;

  const onContinue = () => setWorkflow(WORKFLOWS.PROFILES);
  const onDismiss = () => setWorkflow(null);

  useEffect(() => {
    if (shouldShowSuccessToast && !toastShown) {
      setToastShown(true);
      setWorkflow(null);
      success('Access granted successfully');
    }
  }, [setWorkflow, shouldShowSuccessToast, toastShown]);

  return (
    <DialogContainer onDismiss={onDismiss}>
      {isDialogOpen && (
        <AlertDialog
          width="100%"
          maxWidth="600px"
          cancelLabel="Close"
          variant="confirmation"
          primaryActionLabel="Continue"
          title="Access granted successfully"
          onSecondaryAction={onDismiss}
          onPrimaryAction={onContinue}
        >
          <Flex direction="column" gap="size-250">
            <Text>
              Next steps are to add product profiles for your integration&apos;s service account to gain access to
              granular features.
            </Text>
            <Text>
              This step must be completed by the system administrator of your organization&apos;s Adobe account.
            </Text>
          </Flex>
        </AlertDialog>
      )}
    </DialogContainer>
  );
};

export default AccessGrantedDialog;
