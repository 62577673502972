import { ActionButton, Content, ContextualHelp, DialogTrigger, Flex, Link, Text } from '@adobe/react-spectrum';
import { TBody, TH, THead, TR, Table } from '@react/react-spectrum/Table';
import Document from '@spectrum-icons/workflow/Document';
import Download from '@spectrum-icons/workflow/Download';
import EmailOutline from '@spectrum-icons/workflow/EmailOutline';
import Help from '@spectrum-icons/workflow/Help';
import Info from '@spectrum-icons/workflow/Info';
import LinkOutLight from '@spectrum-icons/workflow/LinkOutLight';
import User from '@spectrum-icons/workflow/User';
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { useStore } from '../../../../store';
import { buildAppListingUrl, formatDate, getExtensionDetails } from '../../../../utils/ECAppsExtensionsHelper';
import { UserDataContext } from '../../../App';
import ECCardButton from '../buttons/ECCardButton';
import ManageContactPublisherDialog from './ManageContactPublisherDialog';
import AppStatusLight from './common/AppStatusLight';
import ProductEndpoints from './common/ProductEndpoints';
import { AppDetailsSection, AppDetailsTD, InlineMessage } from './common/StyledComponents';
import EnvList from './env/List';

//STYLED COMPONENTS
const StyledRow = styled(TR)`
  pointer-events: none;
  vertical-align: center;
`;
const ecAppsLoadingSelector = (state) => state.ecAppsLoading;

/**
 * Used only for App Builder details page
 */
export const AppManageButtons = ({ app }) => {
  const selectedOrg = useStore((state) => state.selectedOrg);
  const loading = useStore(ecAppsLoadingSelector);
  const isPublic = app?.distribution === 'public';
  const navigate = useNavigate();
  const downloadEcAppCode = useStore((state) => state.downloadEcAppCode);
  const { accessToken } = useContext(UserDataContext);
  // Fetch static Data
  const appBuilderExtensionPoints = useStore(
    useCallback((state) => {
      return state.appBuilderExtensionPoints;
    }, [])
  );

  const handleRedirect = () => {
    navigate(`/manage/apps/ec/${selectedOrg.code}/ABD/list`);
  };

  const downloadCode = async (app) => {
    const response = await downloadEcAppCode(accessToken, selectedOrg.code, app?.appVersionId);
    if (response.ok) {
      // Construct the 'a' element
      var link = document.createElement('a');
      link.download = response?.data?.appId || 'appCode';
      link.target = '_blank';

      // Construct the URI
      link.href = response?.data?.downloadUrl;
      document.body.appendChild(link);
      link.click();

      // Cleanup the DOM
      document.body.removeChild(link);
    }
  };

  const renderActionButtons = (type) => {
    switch (type) {
      case 'support':
        return (
          <DialogTrigger>
            <ActionButton label="Get support">
              <Help />
              <Text>Get support</Text>
            </ActionButton>
            <ManageContactPublisherDialog appId={app?.id} />
          </DialogTrigger>
        );
      case 'documentation':
        return (
          <ActionButton
            label="View documentation"
            onPress={() => {
              const helpUrl = app?.assets?.find((item) => item?.type === 'document');
              window.open(helpUrl?.url, '_blank');
            }}
          >
            <Document />
            <Text>View documentation</Text>
          </ActionButton>
        );
      case 'listing':
        return (
          <ActionButton
            label="View listing"
            onPress={() => window.open(buildAppListingUrl(app), '_blank', 'noreferrer')}
          >
            <LinkOutLight />
            <Text>View listing</Text>
          </ActionButton>
        );
      case 'download_code':
        return (
          <ActionButton label="Download Code" onPress={() => downloadCode(app)}>
            <Download />
            <Text>Download Code</Text>
          </ActionButton>
        );
      default:
        return;
    }
  };

  return (
    <Flex justifyContent="end" flexBasis="50%" gap="size-550">
      {isPublic && (
        <Flex gap="8px">
          {renderActionButtons('support')}
          {renderActionButtons('documentation')}
          {app?.isRetractedApp === false && renderActionButtons('listing')}
          {app?.isDownloadable && app?.status.toLowerCase() === 'published' && renderActionButtons('download_code')}
        </Flex>
      )}
      <ECCardButton
        app={app}
        loading={loading}
        appBuilderExtensionPoints={appBuilderExtensionPoints}
        isReviewButton={app?.status.toLowerCase() === 'pending'}
        isAdmin={selectedOrg?.role === 'ADMIN'}
        redirectHandler={handleRedirect}
      />
    </Flex>
  );
};

/**
 * Used only for App Builder details page
 */
export const AppManage = ({ selectedOrg }) => {
  const navigate = useNavigate();
  const { appId } = useParams();
  const [viewMore, setViewMore] = useState(false);
  const [viewMoreNotes, setViewMoreNotes] = useState(false);
  const { app, isPublic, isAppLoading } = useStore(
    useCallback(
      (state) => {
        let apps = [];
        let app = null;
        if (!state.ecAppsLoading) {
          apps = state.allApps;
          app = apps.find((obj) => obj.id === appId) || null;
        }
        return {
          app: app,
          isPublic: app?.distribution === 'public',
          isAppLoading: state.ecAppsLoading,
        };
      },
      [appId]
    )
  );

  useEffect(() => {
    if (!isAppLoading && !app && selectedOrg.code) {
      navigate(`/manage/apps/ec/${selectedOrg.code}/ABD/list`);
    }
  }, [app, isAppLoading, navigate, selectedOrg?.code]);

  // Fetch static Data
  const appBuilderExtensionPoints = useStore(
    useCallback((state) => {
      return state.appBuilderExtensionPoints ?? null;
    }, [])
  );

  return (
    <>
      {app?.distribution === 'public' && app?.status.toLowerCase() === 'pending' && (
        <InlineMessage style={{ backgroundColor: 'var(--spectrum-global-color-static-blue-700)' }}>
          <Info size="S" />
          <Text>
            You cannot install or configure your application until your system administrator has approved your
            application.
          </Text>
        </InlineMessage>
      )}
      <Table quiet>
        <THead>
          <TH style={{ width: '20%' }}>
            <Flex gap="5px" alignItems="center">
              <User size="S" />
              <Text>{app?.distribution === 'private' ? 'contact' : 'requester'}</Text>
            </Flex>
          </TH>
          <TH style={{ width: '20%' }}>Status</TH>
          <TH style={{ width: '20%' }}>Date acquired</TH>
          <TH style={{ width: '40%' }}>Application Description</TH>
        </THead>
        <TBody>
          <StyledRow>
            <AppDetailsTD style={{ width: '20%' }}>
              <Link UNSAFE_style={{ pointerEvents: 'auto' }}>
                <a
                  href={`mailto:${app?.distribution === 'private' ? app?.requestedBy : app?.submittedBy}`}
                  target={'_blank'}
                  rel="noopener noreferrer"
                  data-testid="EC_APP_DETAILS__REQUESTER_EMAIL"
                >
                  {app?.distribution === 'private' ? app?.requestedBy : app?.submittedBy}
                </a>
              </Link>
            </AppDetailsTD>
            <AppDetailsTD style={{ width: '20%', paddingLeft: '0' }}>
              <AppStatusLight status={app?.status} data-testid="EC_APP_DETAILS__STATUS" />
            </AppDetailsTD>
            <AppDetailsTD style={{ width: '20%' }} data-testid="EC_APP_DETAILS__DATE_ACQUIRED">
              {app?.distribution === 'private'
                ? app?.lastModifiedDate
                : app?.createdOn
                ? formatDate(app?.createdOn)
                : 'N/A'}
            </AppDetailsTD>
            <AppDetailsTD style={{ width: '40%' }}>
              {app?.description?.length > 150 ? (
                <Text data-testid="EC_APP_DETAILS__DESCRIPTION">
                  {viewMore ? app?.description : `${app?.description?.substring(0, 150)}…`}
                  <Link
                    UNSAFE_style={{ pointerEvents: 'auto' }}
                    marginStart="size-50"
                    isQuiet
                    onPress={() => setViewMore(!viewMore)}
                    data-testid={`EC_APP_DETAILS__DESCRIPTION__${viewMore ? 'VIEW_LESS' : 'VIEW_MORE'}_LINK`}
                  >
                    {viewMore ? 'View less' : 'View more'}
                  </Link>
                </Text>
              ) : (
                <Text data-testid="EC_APP_DETAILS__DESCRIPTION">{app?.description}</Text>
              )}
            </AppDetailsTD>
          </StyledRow>
        </TBody>
      </Table>
      {app?.developersNotes && (
        <Table quiet>
          <THead>
            <TH>Notes</TH>
          </THead>
          <TBody>
            <StyledRow>
              <AppDetailsTD>
                {app?.developersNotes.length > 150 ? (
                  <div>
                    {viewMoreNotes ? app?.developersNotes : `${app?.developersNotes?.substring(0, 150)}…`}
                    <Link
                      UNSAFE_style={{ pointerEvents: 'auto' }}
                      marginStart="size-50"
                      isQuiet
                      onPress={() => setViewMoreNotes(!viewMoreNotes)}
                    >
                      {viewMoreNotes ? 'View less' : 'View more'}
                    </Link>
                  </div>
                ) : (
                  <div>{app?.developersNotes}</div>
                )}
              </AppDetailsTD>
            </StyledRow>
          </TBody>
        </Table>
      )}
      <AppDetailsSection>Application details</AppDetailsSection>
      <Table quiet style={{ width: '100%' }}>
        <THead>
          <TH style={{ width: '20%' }}>Type</TH>
          <TH style={{ width: '20%' }}>Distribution</TH>
          {!isPublic && (
            <TH style={{ width: '60%' }}>
              <Flex gap="5px" alignItems="center">
                <LinkOutLight size="S" />
                <Text>preview link</Text>
              </Flex>
            </TH>
          )}
          {isPublic && (
            <>
              <TH style={{ width: '20%' }}>
                <Flex gap="5px" alignItems="center">
                  <Text>Latest version</Text>
                  <ContextualHelp variant="info">
                    <Content>
                      <Text>To install the latest version, create a new environment and deploy it.</Text>
                    </Content>
                  </ContextualHelp>
                </Flex>
              </TH>
              <TH>
                <Flex gap="5px" alignItems="center">
                  <EmailOutline size="S" />
                  <Text>publisher email</Text>
                </Flex>
              </TH>
            </>
          )}
        </THead>
        <TBody>
          <StyledRow>
            <AppDetailsTD data-testid="EC_APP_DETAILS__TYPE">
              {getExtensionDetails(app, appBuilderExtensionPoints)}
            </AppDetailsTD>
            <AppDetailsTD data-testid="EC_APP_DETAILS__DISTRIBUTION">{!isPublic ? 'Private' : 'Public'}</AppDetailsTD>
            {!isPublic && (
              <AppDetailsTD>
                <Flex wrap="wrap" alignItems="center">
                  {app?.products?.length > 0 && (
                    <Flex wrap="wrap">
                      {app.products.map((item) => (
                        <ProductEndpoints key={app.id + item.name} product={item} />
                      ))}
                    </Flex>
                  )}
                </Flex>
              </AppDetailsTD>
            )}
            {isPublic && (
              <>
                <AppDetailsTD data-testid="EC_APP_DETAILS__VERSION">{app?.version}</AppDetailsTD>
                <AppDetailsTD>
                  <Link UNSAFE_style={{ pointerEvents: 'auto' }}>
                    <a
                      href={`mailto:${app?.support?.email}`}
                      target={'_blank'}
                      rel="noopener noreferrer"
                      data-testid="EC_APP_DETAILS__PUBLISHER_EMAIL"
                    >
                      {app?.support?.email}
                    </a>
                  </Link>
                </AppDetailsTD>
              </>
            )}
          </StyledRow>
        </TBody>
      </Table>
      {isPublic && app.status.toLowerCase() === 'published' && <EnvList app={app} orgId={selectedOrg.code} />}
    </>
  );
};
