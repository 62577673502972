//@ts-check
import { jilApiClient } from '../../utils/RequestClient';

/**
 * @param {object} params
 * @param {string} params.orgId
 * @param {string} params.userId
 * @param {string} params.token
 * @param {AbortSignal} [params.signal]
 * @returns {Promise<import('@action-types/user').User>}
 * @throws {import('@action-types/RequestError').RequestError<import('@action-types/user').JilApiErrorResponse>}
 */
const getUser = ({ signal, token, orgId, userId }) =>
  jilApiClient.fetch({
    endpoint: `/organizations/${orgId}/users/${userId}`,
    requestOptions: {
      signal,
      headers: {
        authorization: `Bearer ${token}`,
        accept: 'application/json',
      },
    },
  });

export default getUser;
