//@ts-check
import { useDialogContainer } from '@adobe/react-spectrum';
import { success } from '@react/react-spectrum/Toast/js/ToastContainer';
import { useEffect, useState } from 'react';

import useEditProductProfiles from '../../../../../actions/jilApi/selectors/useEditProductProfiles';
import useUserDetails from '../../../../../actions/jilApi/selectors/useUserDetails';

/**
 * @param {string} technicalAccountId
 * @param {import('@action-types/user').PatchProductProfilesMutationParams['productProfiles']} editedProductProfiles
 */
const useSaveProductProfiles = (technicalAccountId, editedProductProfiles) => {
  const { dismiss } = useDialogContainer();
  const [pollEnabled, setPollEnabled] = useState(false);
  const shouldPoll = pollEnabled && editedProductProfiles.length > 0;
  const editProfilesMutation = useEditProductProfiles();
  useUserDetails(technicalAccountId, { refetchInterval: shouldPoll ? 1000 : false });

  const saveProductProfiles = async () => {
    try {
      await editProfilesMutation.mutateAsync(
        {
          showErrorToast: false,
          userId: technicalAccountId,
          productProfiles: editedProductProfiles,
        },
        { onSuccess: () => setPollEnabled(true) }
      );
    } catch (error) {
      // Do nothing. The error will be handled by the useEditProductProfiles hook.
    }
  };

  useEffect(() => {
    if (pollEnabled && !editedProductProfiles?.length) {
      setPollEnabled(false);
      dismiss?.();
      success('Product profiles updated successfully');
    }
  }, [dismiss, editedProductProfiles?.length, pollEnabled]);

  return { saveProductProfiles, editProfilesMutation, refetchingSelectedProfiles: shouldPoll };
};

export default useSaveProductProfiles;
